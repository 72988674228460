import * as React from "react"
import { graphql } from "gatsby"

import { Box, 
  Container,
} from "@chakra-ui/react"

  import Seo from "../../components/seo"
  import BackgroundImage from "../../components/Backgrounds/BackgroundImage"
  import SidebarNav from "../../components/SidebarNav/SidebarNav"
  import MainContentBox from "../../components/MainContentBox/MainContentBox"
  import MobileNav from "../../components/MobileNav/MobileNav"
  import Footer from "../../components/Footer/Footer"
  import PageHero  from "../../components/PageHero/PageHero"
  import SliceZone from "../../components/SliceZone/SliceZone"
  import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
  import { ChevronRightIcon } from "@chakra-ui/icons"
  import { getImage } from "gatsby-plugin-image"

  export const query = graphql`
  query LegacyPage {
    prismicPage(uid: {eq: "legacy"}) {
      data {
        body {
          ... on PrismicPageDataBodyBlogPostContent {
            id
            slice_type
            items {
              post_content {
                raw
              }
            }
          }
          ... on PrismicPageDataBodyOurWorkCards {
            id
            slice_type
            items {
              project_title {
                text
              }
              project_link {
                uid
                url
              }
              project_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED
                      formats: AUTO
                      layout: FULL_WIDTH
                      aspectRatio: 1.5
                      transformOptions: {cropFocus: CENTER})
                  }
                }
              }
              brief_project_description {
                text
              }
            }
          }
          ... on PrismicPageDataBodyOurWorkTabs {
            id
            slice_type
            items {
              tab_content {
                raw
              }
              tab_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      formats: AUTO
                      placeholder: BLURRED
                      quality: 60
                    )
                  }
                }
                alt
              }
              tab_subtitle {
                text
              }
              tab_title {
                text
              }
            }
            primary {
              tab_section_subtitle {
                text
              }
              tab_section_title {
                text
              }
            }
          }
          ... on PrismicPageDataBodyCallToAction {
            id
            slice_type
            items {
              button_color
              button_label
              button_link {
                url
                uid
              }
            }
            primary {
              title {
                text
              }
              paragraph {
                text
              }
              background_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        page_title {
          text
        }
        page_description {
          text
        }
        featured_images {
          featured_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
        cta_buttons {
          button_color
          cta_button_link {
            url
          }
          cta_button_label {
            text
          }
        }
      }
    }
  } 
`

  const GrandTarghee = ({ pageContext, location, data }) => {
    const {
      breadcrumb: { crumbs },
    } = pageContext

    const pageLocation = location.pathname

    if (!data) return null
    const document = data.prismicPage.data
  
    const heroContent = {
      title: document.page_title.text,
      description: document.page_description.text,
      ctaButtons: document.cta_buttons,
      featuredImage: document.featured_images[0]?.featured_image.localFile
    }

    const customCrumbLabel = heroContent.title

    // Get the background image if it exists
    const backgroundImage = document.body.background_image?.localFile
    
    return (
      <>
      <Seo title="Teton Valley Advocates | Legacy" />
      <BackgroundImage
        backgroundImage={getImage(backgroundImage)}
      >
        <Container maxW="container.xl">
          <MobileNav />
          <Box paddingTop="20" width="100%">
              <SidebarNav 
                pathName={pageLocation}
              />
          <MainContentBox>
             <PageHero 
              title={heroContent.title}
              description={heroContent.description}
            />
          <Box p="4" backgroundColor="rgba(255, 255, 255, 0.8)" textAlign="center">
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator={<ChevronRightIcon />}
              crumbLabel={customCrumbLabel}
          />
          </Box>
          <Box>
            <SliceZone sliceZone={document.body} />
          </Box>
            <Footer />
          </MainContentBox>
          </Box>

        </Container>

    </BackgroundImage>
      </>
    )
  }

  

  export default GrandTarghee
